<template>
  <div class="text-center">
    <div v-if="!rescheduleDone" class="title text-left">
      <v-btn text @click="handleBack()">
        <v-icon small class="mr-2">fas fa-arrow-left</v-icon>Back
      </v-btn>
    </div>
    <p class="salutation">Reschedule your call</p>
    <p v-if="!rescheduleDone" class="mt-1">
      Select a different slot for your Video KYC
    </p>
    <v-divider class="ma-5"></v-divider>
    <div v-if="!rescheduleDone">
      <div class="rescheduleContainer text-left">
        <p class="title">Select a date</p>
        <v-dialog
          ref="menu2"
          v-model="dateDialog"
          :return-value.sync="date"
          persistent
          width="300px"
          color="signzy_color"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              v-model="date"
              label="Select a date"
              append-icon="fas fa-calendar-week"
              readonly
              v-on="on"
              class="dateWrapper"
              color="signzy_color"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="getToday()"
            :max="getFutureDate()"
            :allowed-dates="allowedDates"
            color="signzy_color"
            v-model="date"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="signzy_color" @click="dateDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              text
              color="signzy_color"
              @click="
                calculateSlots();
                $refs.menu2.save(date);
              "
              >OK</v-btn
            >
          </v-date-picker>
        </v-dialog>
        <p class="title">Pick a time slot (IST)</p>
        <p>{{ timeSlotHint }}</p>
        <v-row>
          <v-col
            v-for="slot in timeSlots"
            :key="slot"
            sm="4"
            cols="6"
            md="3"
            xl="2"
            lg="2"
          >
            <v-btn
              block
              :outlined="selectedTimeSlot != slot"
              :dark="selectedTimeSlot == slot"
              @click="selectedTime(slot)"
              :style="buttonStyling(slot)"
              >{{ slot }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-divider class="ma-5"></v-divider>
      <div v-if="timeSlots.length != 0" class="text-center">
        <v-checkbox
          v-model="consent"
          color="signzy_color"
          style="font-size: 10px !important"
          class
          :label="rescheduleConsent"
        ></v-checkbox>
        <div class="my-2">
          <v-btn
            :disabled="!isTimeSlotSelected || !consent"
            class="customBtn"
            :style="{
              backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || '#1365c0'}`,
              color:`${$store.getters.callConfig.buttonTextColor || null}`
            }"
            @click="reschedule()"
            >Reschedule</v-btn
          >
        </div>
      </div>
    </div>
    <div v-else>
      <p class="headline mt-1">
        You have successfully booked your call on {{ date }}, at
        {{ selectedTimeSlot }}!
      </p>
    </div>
  </div>
</template>

<script>
import { verifySession, getSession } from "@/Plugins/videoProcess";
import { end_points, vcip_end_points, host, constants } from "@/config";
import { logReportData } from "@/Plugins/reportLogger.js";
import enLib from "crypto-js/aes";
import jwt from "jsonwebtoken";
import { mapActions } from "vuex";
let offset = (new Date().getTimezoneOffset() / 60) * -1;
let allowedSchedulingDays = [0,1,2,3,4,5,6];
import { endCustomer } from "@/assets/subStatus.json";

export default {
  data: () => ({
    rescheduleConsent: "I agree to reschedule to consent",
    email: "",
    rescheduleDone: false,
    meetingCode: "",
    dateDialog: false,
    timeDialog: false,
    isSessionPresent: false,
    date: new Date(new Date().getTime() + offset * 3600000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    time: "",
    backWatcherUrl: "",
    backWatcher: false,
    modal: false,
    menu2: false,
    consent: false,
    incoming: "",
    timeSlots: [],
    isTimeSlotSelected: false,
    selectedTimeSlot: "",
    startsAt: "10:00",
    endsAt: "20:00",
    rescheduleWindow: "3",
    timeSlotHint: "Please select a date to view slots",
    host: host,
    logObject: endCustomer.reschedulePage
    // reschedulingAllowed: true,
  }),
  props: ["requestId", "callData", "socket"],
  methods: {
    ...mapActions(["pageEntry"]),
    selectedTime(slot) {
      this.isTimeSlotSelected = true;
      this.selectedTimeSlot = slot;
    },
    buttonStyling(slot){
      if(this.selectedTimeSlot != slot) {
        return {
          color: `${this.$store.getters.callConfig.buttonOutlineColor || '#1365c0'}`,
          borderColor: `${this.$store.getters.callConfig.buttonOutlineColor || '#1365c0'}`
        }
      } else {
        return {
          color:`${this.$store.getters.callConfig.buttonTextColor || '#ffffff'}`,
          backgroundColor: `${this.$store.getters.callConfig.buttonOutlineColor || '#1365c0'}`
        }
      }
    },
    checkAndSaveTime() {
      if (this.isSafeToProceed()) {
        this.$refs.menu.save(this.time);
      }
    },
    isSafeToProceed() {
      let dts = `${this.date}T${this.time}`;
      let timeDiff = Date.parse(dts) - Date.now();
      if (timeDiff <= 0) {
        eventBus.$emit(
          "vueSnack",
          "Schedule calls cannot have a time in past!"
        );
      } else if (timeDiff <= 300000) {
        eventBus.$emit(
          "vueSnack",
          "Schedule call cannot be set within 5 minutes!"
        );
      } else if (
        this.schedulingWindowSeconds &&
        timeDiff > this.schedulingWindowSeconds * 1000
      ) {
        eventBus.$emit(
          "vueSnack",
          "Schedule call cannot be set beyond the window allocated!"
        );
      } else {
        return true;
      }
      return false;
    },
    allowedDates: val => {
      let weekDay = (new Date(val)).getDay();
      if(allowedSchedulingDays.includes(weekDay)){
        return val;
      }
    },
    getToday() {
      let d = new Date();
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getFutureDate() {
      let d = new Date(
        Date.now() + 1000 * 60 * 60 * 24 * (parseInt(this.rescheduleWindow) + 1)
      );
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getTodayTime() {
      let time = new Date();
      let hours = time.getHours(),
        minutes = time.getMinutes();

      if (hours.toString().length < 2) hours = `0${hours}`;
      if (minutes.toString().length < 2) minutes = `0${minutes}`;

      return [hours, minutes].join(":");
    },
    goBack() {
    this.cleanUpListeners();
      if (this.incoming == "queue") {
        this.$router.replace({
          name: "initiateQueue",
        });
      } else {
        this.$router.replace({
          name: "waiting",
        });
      }
    },
    dateStringToTime(dateStr) {
      let a = dateStr.split(/[^0-9]/);
      return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
    },
    calculateSlots() {
      this.timeSlots = [];
      this.selectedTimeSlot = "";
      this.isTimeSlotSelected = false;
      let ap = ["AM", "PM"]; // AM-PM
      let timeInterval = 15;
      let currentTime = Date.now();
      let currentDateObj = new Date(currentTime);
      let startMinutes = parseInt(this.startsAt.split(":")[1]),
        startHours = parseInt(this.startsAt.split(":")[0]),
        endMinutes = parseInt(this.endsAt.split(":")[1]),
        endHours = parseInt(this.endsAt.split(":")[0]);

      //Calculating the nearest whole time + 15mins
      if (
        this.date ==
          new Date(currentDateObj.getTime() + offset * 3600000)
            .toISOString()
            .split("T")[0] &&
        startHours * 60 + startMinutes <
          currentDateObj.getHours() * 60 + currentDateObj.getMinutes()
      ) {
        startHours = currentDateObj.getHours();
        if (currentDateObj.getMinutes() < 15) {
          startMinutes = 30;
        } else if (currentDateObj.getMinutes() < 30) {
          startMinutes = 45;
        } else if (currentDateObj.getMinutes() < 45) {
          startMinutes = 0;
          startHours = parseInt(startHours) + 1;
        } else {
          startMinutes = 15;
          startHours = parseInt(startHours) + 1;
        }
      }

      //Fix for vkyc-3595
      let hourDisplay;
      let mntDisplay;

      //loop to increment the time and push results in array
      startHours = startHours * 60 + startMinutes;
      console.log(startHours);
      for (let i = 0; startHours <= endHours * 60 + endMinutes; i++) {
        let hh = Math.floor(startHours / 60); // getting hours of day in 0-24 format
        let mm = startHours % 60; // getting minutes of the hour in 0-55 format
        /*
        this.timeSlots[i] =
          ("0" + (hh % 12)).slice(-2) +
          ":" +
          ("0" + mm).slice(-2) +
          ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        */
        hourDisplay = ("0" + (hh % 12)).slice(-2) ;
        mntDisplay = ("0" + mm).slice(-2);
        var ampm=ap[Math.floor(hh / 12)];   //00:15PM should be displayed as 12:15PM.
        if(hh==12) {
            hourDisplay = 12;
         }
        this.timeSlots[i] = hourDisplay+":"+mntDisplay+ampm;

        startHours = startHours + timeInterval;
      }

      //Keeping this commented as the worst case scenario is not considered anymore
      // if(!this.reschedulingAllowed){
      //   this.timeSlots.splice(0, this.timeSlots.length);
      //   this.date = new Date(new Date().getTime() + offset * 3600000).toISOString().substr(0, 10);
      //   // this.date = "";
      //   eventBus.$emit("vueSnack", "No slots available for rescheduling!");
      // }

      if (this.timeSlots.length == 0) {
        this.timeSlotHint = "There are no available time slots!";
      } else {
        this.timeSlotHint = "Please select any one as per your convinience!";
      }
    },
    handleBack() {
      this.cleanUpListeners();
      if (this.$route.query.from === "dropHandler") {
        this.socket.emit("manualDisconnection", { cancelCleanUp: true });
        console.log("Taking back to drop handler page");
        this.$router.replace({
          path:
            "/unexpectedDrop/" +
            (this.$route.query.reason ? this.$route.query.reason : "default"),
        });
      } else {
        if (this.backWatcher) {
          console.log("Taking back to drop handler page");
          this.socket.emit("manualDisconnection", { cancelCleanUp: true });
          this.$router.replace({
            path: this.backWatcherUrl,
          });
        } else {
          // sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("backFromReschedule", true);
          this.$router.replace({ name: "prerequisite" });
        }
      }
    },
    async reschedule() {
      try {
        let selfIns = this;
        let time = this.selectedTimeSlot;
        if (this.selectedTimeSlot.substr(-2) == "PM") {
          let [h, m] = this.selectedTimeSlot.substr(0, 5).split(":");
          // time = `${parseInt(h) + 12 > 23 ? "00" : parseInt(h) + 12}:${m}`;
          if(parseInt(h)==12){
            time = `12:${m}`;
          }
          else
            time = `${parseInt(h) + 12 > 23 ? "00" : parseInt(h) + 12}:${m}`;
        } else {
          time = this.selectedTimeSlot.substr(0, 5);
        }
        console.log(`Reschedule Slot Time Selected : ${this.date}T${time}`);
        let fromEpochTime =
          this.dateStringToTime(`${this.date}T${time}:00`).getTime() +
          offset * 3600000;
        let toEpochTime =
          this.dateStringToTime(`${this.date}T${time}:00`).getTime() +
          900000 +
          offset * 3600000;
        console.log("fromEpochTime", Date(fromEpochTime));
        console.log("toEpochTime", Date(toEpochTime));

        if (this.requestId) {
          let userData = await axiosInstance.get(
            end_points.polling_api(this.requestId)
          );
          if (
            this.callData.configuration.isScheduleInformation == undefined ||
            this.callData.configuration.isScheduleInformation
          ) {
            // expires in 2 mins
            let token = jwt.sign({data:this.requestId}, this.$store.getters.envValues.otpEncKey, { expiresIn: 60 * 2 });

            // encryption on req body
            let dataToPost = {
              email: userData.data["input"]["callData"]["users"][0]["email"],
              name: userData.data["input"]["callData"]["users"][0]["name"],
              rescheduleDate: selfIns.date,
              rescheduleTime: time,
              token
            };
            dataToPost = JSON.stringify(dataToPost)
            dataToPost = enLib.encrypt(dataToPost, this.$store.getters.envValues.otpEncKey).toString();

            await axiosInstance.post(vcip_end_points.send_email_reschedule, {dataToPost});
          }

          // expires in 2 mins
          const token = jwt.sign({data:this.requestId}, this.$store.getters.envValues.otpEncKey, { expiresIn: 60 * 2 });
          
          const scheduledUrl= `${this.host}landing/${this.requestId}?isSchedule=true`

          await axiosInstance.post(end_points.reschedule_call, {
            token,
            requestId: this.requestId,
            deferred: Date.now(),
            scheduled: {
              from: fromEpochTime,
              to: toEpochTime,
              callback: vcip_end_points.callback_url,
              scheduledUrl,
              token
            },
          });

          await axiosInstance.post(
            end_points.update_call_timestamp(this.requestId),
            {
              scheduledCall: Date.now() + offset * 3600000,
              scheduledUrl,
            }
          );
          try {
            let initiationId = sessionStorage.getItem("initiationId");
            let formData = { ...this.logObject.callRescheduled };
            formData.scheduledTime = `${this.date}T${time}`;

            formData.scheduledUrl = scheduledUrl
            formData.currentProcess = `Call rescheduled event called`;
            formData.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,formData);
            try {
              if (sessionStorage.getItem('initiationId')) {
                sessionStorage.removeItem('initiationId')
              }
            } catch (err) {
              console.log("Could not delete previous session's initaitoan id", err);
            }
        }
        catch(err) {
          console.log(err);
        }
        let eventObject = {
          message: "eventOnCallScheduled",
          time: (new Date(fromEpochTime - (offset * 3600000))).toISOString(),
          scheduledUrl
        }
        if(this.$store.getters.scheduledCallExpiry !== false){
          eventObject["expiry"] = (new Date(fromEpochTime - (offset * 3600000) + this.$store.getters.scheduledCallExpiry)).toISOString();
        }
        window.parent.postMessage({ ...eventObject }, "*");
        //window.parent.postMessage({ message: "videoUploadingDone" }, "*"); //IO Specific
        eventBus.$emit("vueSnack", "Successfully Rescheduled!");
        this.rescheduleDone = true;
        } else {
          eventBus.$emit("vueSnack", "Request ID not found!");
        }
      } catch (e) {
        console.log("error", e);
        eventBus.$emit("vueSnack", "Invalid User");
      }
    },
    cleanUpListeners () {
      window.removeEventListener("popstate", this.handleBack);
    },
  },
  beforeDestroy() {
      this.cleanUpListeners();
  },
  beforeRouteEnter (to, from, next) {
    if(sessionStorage.getItem("onPage") == "Reschedule"){
      sessionStorage.setItem("RescheduleRefreshed", true);
      sessionStorage.setItem("triggerCustomMIS", true);
      sessionStorage.setItem("fromPage", "Reschedule");
    }
    next();
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch("pageExitCleanUp", "Reschedule");
    let path = to.path.toString().split('/').pop();
    if(path == "prerequisite" && sessionStorage.getItem("disableInstructionsPage") && !sessionStorage.getItem("RescheduleRefreshed")){
      sessionStorage.setItem("backFromReschedule", true);
    }
    next();
  },
  beforeCreate(){
    if(sessionStorage.getItem("RescheduleRefreshed")){
      if(sessionStorage.getItem("disableInstructionsPage")){
        this.$router.replace({ name: "prerequisite" });
      } else {
        this.$router.replace({ name: "instructions" });
      }
    }
  },
  created() {
    this.pageEntry("Reschedule");
    window.addEventListener("popstate", this.handleBack);
    if (this.$route.query.from === "dropHandler") {
      this.backWatcher = true;
      this.backWatcherUrl =
        "/unexpectedDrop/" +
        (this.$route.query.reason ? this.$route.query.reason : "default");
    } else {
      this.backWatcher = false;
    }
    this.time = this.getTodayTime();

    if (this.callData.configuration.allowedSchedulingDays && this.callData.configuration.allowedSchedulingDays.length > 0) {
      allowedSchedulingDays = this.callData.configuration.allowedSchedulingDays;
    }

    //Checking if the current date is allowed for rescheduling
    while(!allowedSchedulingDays.includes((new Date(this.date)).getDay())){
      let tempDate = new Date(this.date);
      tempDate.setDate(tempDate.getDate()+1);
      this.date = tempDate.toISOString().substr(0, 10);
      this.time = "00:00"
    }

    //Keeping this commented as the worst case scenario is not considered anymore
    // if(new Date(this.date) > new Date(this.getFutureDate())){
    //   this.reschedulingAllowed = false;
    // }

    //Added MIS logging when user enters the Rescheduling Page
    try{
      let initiationId = sessionStorage.getItem('initiationId');
      let data = {};
      data.onPage = "Reschedule"
      data.currentProcess = "User was on reschedule page";
      data.callId = this.requestId ? this.requestId : "";
      logReportData(this.socket,initiationId,data);
    } catch (error){
      console.log(error);
    }

    if (this.callData.configuration.rescheduleConsent) {
      this.rescheduleConsent = this.callData.configuration.rescheduleConsent;
    }
    this.incoming = this.$route.params.incoming;
    this.startsAt = this.callData.configuration.startsAt || "08:00";
    this.endsAt = this.callData.configuration.endsAt || "20:00";
    this.host = this.callData.configuration.customDomain || host;
    this.rescheduleWindow =
      (this.callData.configuration.rescheduleWindow || "3") - 1;
    this.calculateSlots();
  },
};
</script>

<style scoped>
.timewrapper {
  border: 1px white solid;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  margin-top: 10px;
}
.salutation {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 4vh;
  line-height: 1em;
  color: black !important;
}
.instructionText {
  font-size: 16px;
}
.feedback {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.permissionProgress {
  position: absolute;
  color: white;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
}
.videoGrad {
  background: transparent
    linear-gradient(180deg, #00000000 0%, #00000004 26%, #000000 100%) 0% 0%
    no-repeat padding-box;
}
#videoGradient {
  position: fixed;
}
#reflection {
  width: 100%;
  background: black;
}
.customBtn {
  color: white;
  width: 200px;
}
.dateWrapper {
  width: 100vh;
  max-width: 350px;
}
.rescheduleContainer {
  color: black;
}
</style>
