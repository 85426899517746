<template>
  <div class="text-center">
    <p class="salutation">{{ instructionHeader[step] }}</p>
    <p class="mt-1">{{ instructionSubHeader[step] }}</p>
    <v-row justify="center">
      <v-col sm="12" cols="12" md="10" xl="8" lg="10">
        <div class="feedback">
          <video id="reflection" autoplay playsinline></video>
          <div id="videoGradient" class="videoGrad"></div>
          <div id="permissionBox" class="permissionProgress">
            <div class="progress pa-2 pb-2" style="margin: 0 auto">
              <v-row v-if="step == 'initiate'">
                <v-col sm="6" cols="6" md="6" xl="6" lg="6" style="padding-bottom:1%">
                  <b>Position in queue</b>
                
                </v-col>
                <v-col  v-if="showETA" sm="6" cols="6" md="6" xl="6" lg="6" style="padding-bottom:1%">
                  <b>Approximate wait time</b>
                  
                </v-col>
              </v-row>
               <v-row v-if="step == 'initiate'">
                <v-col sm="6" cols="6" md="6" xl="6" lg="6" style="padding-top:1.5%; padding-bottom:1.5%">           
                  <div class="timewrapper" :style="{color: `${$store.getters.callConfig.textColor || null}!important`}">{{ numPeople }}</div>
                </v-col>
                <v-col v-if="showETA" sm="6" cols="6" md="6" xl="6" lg="6" style="padding-top:1.5%;padding-bottom:1.5%">
                  <div v-if="eta " class="timewrapper" :style="{color: `${$store.getters.callConfig.textColor || null}!important`}">{{ eta }} Minute(s)</div>
                  <div v-else class="timewrapper" :style="{color: `${$store.getters.callConfig.textColor || null}!important`}">Loading..</div>
                </v-col>
              </v-row>
              
              <v-row v-else-if="step == 'picked'" justify="center">
                <v-col sm="6" cols="12" md="6" xl="6" lg="6">
                  <b>Approximate wait time</b>
                  <div v-if="ringerSeconds" class="timewrapper" :style="{color: `${$store.getters.callConfig.textColor || null}!important`}">
                    {{ ringerSeconds }}
                  </div>
                </v-col>
              </v-row>
              <v-row v-else justify="center">
                <v-col sm="6" cols="12" md="6" xl="6" lg="6">
                  <b style="display: block" class="mb-2"
                    >Unable to connect you to a {{displayName}} representative.</b
                  >
                  <div @click="workOnNewCall()" class="timewrapper">Retry</div>
                </v-col>
              </v-row>
            </div>
            <span class="pb-2" style="display: block"
              >Wait time may vary as per the availability of the {{displayName}} representatives</span
            >
            <p v-if="numPeople > 3" style="color: #ffe200; font-size: 12px">
              We are experiencing a high volume of Video KYC sessions today.
              Waiting time might be longer than usual.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showNoUserAvailable" persistent max-width="550">
      <v-card>
        <v-card-text v-if="isCiti" class="text-center pa-5">
          <p class="subtitle-2">Sorry! We're unable to connect you to a Citibanker at this time.<br><br>
          You can set an appointment or try again later, per the timings below,on any working day.<br> 
          9am to 9pm for Credit Cards, and 9am to 6pm for Banking Accounts</p>
        </v-card-text>
        <v-card-text v-else class="text-center pa-5">
          <img src="@/assets/representativeBusy.png" />
          <p class="subtitle-2">No {{displayName}} representative available right now!</p>
        </v-card-text>
        <v-card-actions class="pt-0 mr-2 pb-3">
          <v-spacer></v-spacer>
          <v-btn
            small
            dark
            color="signzy_blue"
            :style="{backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || null}!important`}"
            @click="tryAgain()"
            >Try again</v-btn
          >
          <v-btn v-if="showReschedule" small dark color="success" @click="reschedule()"
            >Schedule Call</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSomethingWentWrong" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center pa-5">
          <img src="@/assets/representativeBusy.png" />
          <p class="subtitle-2">Something went wrong</p>
        </v-card-text>
        <v-card-actions class="pt-0 mr-2 pb-3">
          <v-spacer></v-spacer>
          <v-btn
            v-if="pollingFailure >= 10 || showTryAgain "
            small
            dark
            color="signzy_blue"
            :style="{backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || null}!important`}"
            @click="tryAgain()"
            >Try again</v-btn
          >
          <v-btn v-if="showReschedule" small dark color="success" @click="reschedule()"
            >Schedule Call</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="display: flex; justify-content: center;">
      <v-alert
        :value="priorityUserJoined"
        dense
        dismissible
        type="warning"
        transition="scroll-x-transition"
        style="white-space: pre-line"
        class="mt-3 mb-3"
        :max-width="messageBoxWidth"
      >
        Your queue position has been adjusted slightly. Thanks for your understanding!
      </v-alert>
    </div>
    <div v-if="showReschedule" class="text-center mt-3 mb-5">
      <a @click="showRescheduleWarning()">Click here</a> if you want to reschedule your
      Video KYC sometime later.
      <v-btn
        v-if="isFailure"
        @click="startProcess()"
        class="customBtn"
        dark
        color="warning"
        >Retry</v-btn
      >
    </div>
    <v-dialog v-model="rescheduleWarning" persistent max-width="550">
      <v-card>
        <v-row
          class="ma-0 pa-3"
          style="background-color: #fcfcfc; border-radius: 8px;"
          justify="center"
          align="center"
          >
          <v-col align="center" style="padding-left: 4%; padding-right:4%">
            <img
              class="pb-3"
              src="@/assets/error.svg"
              style="width: 75px"
            />
            <p
              class="mb-4"
              :style="{
                color: $store.getters.callConfig.textColor || '#0065A3',
                fontSize: '18px',
                fontWeight: '600'
                }"
            >
              You will be removed from the waiting queue if you choose to reschedule.
            </p>
            <p v-if="rescheduleDuration"
              style="
                color: #0f0f0f;
                font-size: 13px;
                letter-spacing: 0px;
                font-weight: 500;
                "
              >
              Note: You can only reschedule to upto {{rescheduleDuration}} days from now.
            </p>     
            <v-row
                class="mx-0 py-2"
                style="width: 100%;"
                justify="center"
              >
              <div style="max-width: 100%  ;margin:3%">
                <v-btn
                  @click="showRescheduleWarning()"
                  small
                  class="px-4 py-4"
                  color="primary"
                  :style="{
                    backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || null}!important`,
                    color:`${$store.getters.callConfig.buttonTextColor || null}!important`
                  }"
                  light
                  >Go Back</v-btn
                > 
              </div>
              <div style="max-width: 100% ;margin:3%">
                <v-btn
                  @click="reschedule()"
                    small
                    class="px-4 py-4"
                    color="primary"
                    :style="{
                      color: `${$store.getters.callConfig.buttonOutlineColor || null}!important`,
                      borderColor: `${this.$store.getters.callConfig.buttonOutlineColor || null}!important`
                    }"
                    outlined
                    >Reschedule
                </v-btn>
              </div>
            </v-row>
          </v-col>            
        </v-row>
      </v-card>
    </v-dialog>       
  </div>
</template>

<script>
import {
  padDoubleDigits,
  getRandomInt,
  convertToRinger,
} from "@/Plugins/utils.js";
import { end_points, base_url, constants } from "@/config";
import axios from "axios";
import { SignzyMessagingService } from "@/Plugins/SignzyMessagingService";
import jwt from "jsonwebtoken";
import { verifySession } from "@/Plugins/videoProcess";
import {logReportData}  from  "@/Plugins/reportLogger.js";
import { mapActions } from "vuex";
import { endCustomer, reAgent } from "@/assets/subStatus.json";
let offset = (new Date().getTimezoneOffset() / 60) * -1;
let timeWrapperColor;
export default {
  data: () => ({
    instructionHeader: {
      initiate: "Your call is being connected",
      picked: "An official has been assigned",
      missed: "Uh oh!",
    },
    instructionSubHeader: {
      initiate: "Please wait while we connect you to our bank official",
      picked: "Please wait while your call is being picked",
      missed: "Looks like all our Officials are busy at the moment.",
    },
    name: "default",
    step: "initiate",
    isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    time: "5 minutes",
    isFailure: false,
    totalSeconds: 300,
    numPeople: 1,
    randomTimeCalculateInterval: 10,
    pollInterval: 3, // default
    waitingStage: true,
    disableReschedule: true,
    showRescheduleButtonInterval: 10,
    youtubeEmbed: "https://www.youtube.com/embed/N4fn2kE3Tj4",
    email: "",
    meetingCode: "",
    isSessionPresent: false,
    sessionId: "",
    poller: null,
    videoStream: undefined,
    ringgingState: false,
    adminCallTimeout: {},
    newCall: true,
    missedCallUpdateFromBackend: true,
    representativeBusy: false,
    ringerSeconds: undefined,
    eta: undefined,
    errorThreshold: 0,
    displayName: "Signzy",
    timeInterval: undefined,
    rescheduleWarning: false,
    showNoUserAvailable: false,
    showSomethingWentWrong: false,
    pollingRequestCompleted: true,
    rescheduleDuration: undefined,
    callInterrupt: true,
    timeStampsUpdated: false,
    initiatedId: null,
    notficationSent: false,
    isCiti : false,
    noBanker: false,
    FrontDebugLabel:"initiateQueuePage",
    alreadyProceeding: false,
    showReschedule: true,
    islogReported: false,
    notficationSent: false,
    pollingFailure: 0,
    showETA: false,
    showTryAgain: false,
    priorityUserJoined: false,
    qPosHistory: 99999999,
    messageBoxWidth: 330,
    rescheduleClicked: false,
    logObject: {endCustomer, reAgent: reAgent.queuePage}
  }),
  beforeRouteEnter(to,from,next) {
    if(sessionStorage.getItem("onPage") == "Queue"){
      sessionStorage.setItem("InitiateQueueRefreshed", true);
      sessionStorage.setItem("triggerCustomMIS", true);
      sessionStorage.setItem("fromPage", "Queue");
    }
    let path = from.path.toString().split('/').pop();
    if(path == "start"){
      return false;
    }
    next();
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch("pageExitCleanUp", "Queue");
    let path = to.path.toString().split('/').pop();
    if(path == "prerequisite" && !sessionStorage.getItem("InitiateQueueRefreshed")){
      //MIS logging events to trigger user pressed back button on x page
      sessionStorage.setItem("backButtonPressed", true);
      sessionStorage.setItem("triggerCustomMIS", true);
      sessionStorage.setItem("fromPage", "Queue");

      if(sessionStorage.getItem("disableInstructionsPage")){
        next();
      } else {
        this.$router.replace({ name: "instructions" });
      }
    } else {
      next();
    }
  },
  beforeCreate(){
    if(sessionStorage.getItem("InitiateQueueRefreshed")){
      sessionStorage.setItem("stopCreatedBlockInitiateQueue", true);
      if(sessionStorage.getItem("disableInstructionsPage")){
        this.$router.replace({ name: "prerequisite" });
      } else {
        this.$router.replace({ name: "instructions" });
      }
    } else {
      if(!this.$store.getters.allowUserInQueue){
        sessionStorage.setItem("stopCreatedBlockInitiateQueue", true);
        this.$router.replace({ name: "prerequisite" });
      }
    }
  },
  async created() {
    this.pageEntry("Queue");
    if(!sessionStorage.getItem("stopCreatedBlockInitiateQueue")){
      if (this.callData.configuration.pollingInterval) {
        // Making default interval as 3 if no value is provided
        this.pollInterval = parseInt( this.callData.configuration.pollingInterval || 3 );
      }
      if (this.callData.configuration.instructionHeader) {
        this.instructions = this.callData.configuration.instructionHeader;
      }
      if (this.callData.configuration.rescheduleWindow) {
        this.rescheduleDuration = this.callData.configuration.rescheduleWindow;
      }
      this.displayName = this.callData.configuration.displayName || "";
      this.showETA = this.callData.configuration.showETA || false;
      this.instructionSubHeader.missed = `Looks like all our ${this.displayName} Officials are busy at the moment.`;
      this.instructionSubHeader.initiate = `Please wait while we connect you to our bank ${this.displayName} Official.`;
      if(this.callData.configuration.waitingMessageRequired) {
        this.instructionSubHeader.initiate = this.callData.configuration.waitingMessage
      }
      if(this.callData.configuration.officialAssignedMessage) {
        this.instructionHeader.picked = this.callData.configuration.officialAssignedMessage;
      }

      //Event based MIS logging to avoid the overwriting of current subStatus
      eventBus.$on("completed-prerequisites-check", ()=> {
        try {
          let initiationId = sessionStorage.getItem('initiationId');
          let data = {};
          data.onPage = "Queue";
          data.currentProcess = "User was on queue page";
          data.callId = this.requestId ? this.requestId : "";
          logReportData(this.socket,initiationId,data);
        } catch(err){
          console.log(err);
        }
      });

      setTimeout(() => {
        let constraints = {
          audio: false,
          video: {
            facingMode: "user",
          },
        };
        let video = document.getElementById("reflection");
        let videoGradient = document.getElementById("videoGradient");
        //Setting width
        let width = `${
          this.$store.getters.endUserData["videoWidth"] || video.offsetWidth
        }px`;
        let height = `${
          this.$store.getters.endUserData["videoHeight"] || video.offsetHeight
        }px`;

        videoGradient.style.width = width;
        videoGradient.style.height = height;
        videoGradient.style.position = "absolute";

        this.messageBoxWidth = width;

        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          video.srcObject = stream;
          this.videoStream = stream;
        });
      }, 50);

      window.onbeforeunload = () => {
        if(this.poller){
          window.clearInterval(this.poller);
        }
        if ((this.step == "initiate" || this.ringgingState) && !this.rescheduleClicked) {
            this.ringgingState = false;
            this.interruptCall({ event: "beforeunload" });
        }
        return null;
      };

      window.addEventListener('popstate' , (e) => {
        if(this.poller){
          window.clearInterval(this.poller);
        }
        if (this.step == "initiate" || this.ringgingState) {
            this.ringgingState = false;
            this.interruptCall({ event: "popstate" });
        }
      }, false)

      let selfIns = this;
      this.$store.commit("setEndUserData", ["requestId", this.requestId]);
      // the below condition ensures not to dequeue the call if user comes directly frpm startvideo page
      // BACKBUTTON STATUS  will be set as true on start video page 
        if(this.$store.getters.backButtonStatus){
            return null
        }  
      this.workOnNewCall();
    } else {
      sessionStorage.removeItem("stopCreatedBlockInitiateQueue");
    }
  },
  beforeDestroy() {
    if (this.poller) {
      window.clearInterval(this.poller);
    }
    try {
      // clearing video stream
      this.videoStream.getTracks().forEach((track)=> {
        //removed track before stoping from media stream object to resolve freezing chrome on android 11
        this.videoStream.removeTrack(track)
        track.stop();
      });
    } catch (error) {
      console.log("ERROR", error);
    }
    if ((this.waitingStage || this.ringgingState ) && !this.rescheduleClicked) {
      this.ringgingState = false;
      if (this.callInterrupt) {
        this.interruptCall();
      }
    }
  },
  methods: {
    ...mapActions(["pageEntry"]),
    showLocalNotification (title, body, swRegistration) {
      const options = {
          body
          // here you can add more properties like icon, image, vibrate, etc.
      };
      if (localStorage.getItem("imageLogo")) {
        options["image"] = localStorage.getItem("imageLogo");
      }
      swRegistration.showNotification(title, options);
    },
    ringerInterval(seconds) {
      this.timeInterval = setInterval(() => {
        this.ringerSeconds = convertToRinger(seconds);
        if (--seconds <= 0) {
          window.clearInterval(this.timeInterval);
        }
      }, 1000);
    },
    async interruptCall (manualDisconnect) {
      if (this.poller) {
        window.clearInterval(this.poller);
      }
      window.clearTimeout(this.adminCallTimeout);
      let currentTime = Date.now();
      if (this.isMultipleSession) {
          try {
            let initiationId = sessionStorage.getItem('initiationId');
            let data = { ...this.logObject.endCustomer.multipleSessions.dropped }
            data.currentProcess = "Dropped on queue due to multiple sessions"
            data.onPage = "Queue"
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          } catch(err){
            console.log(err);
          }
        return;
      }
      if (manualDisconnect && manualDisconnect.event == "reschedule") {
        try {
            let initiationId = sessionStorage.getItem('initiationId');
            let data = {}
            data.currentProcess = "Call rescheduled button clicked from queue called by end user"
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          } catch(err){
            console.log(err);
          }

          const token = jwt.sign({data:this.requestId}, this.$store.getters.envValues.otpEncKey, { expiresIn: 60 * 2 });
          
          axiosInstance.post(end_points.reschedule_call, {
          token,
          requestId: this.requestId,
          interrupted: currentTime,
          abruptDrop:
            this.waitingStage || this.ringgingState
              ? currentTime + offset * 3600000
              : 0,
          missedCall: this.missedCallUpdateFromBackend,
          initiatedId: this.initiatedId !== null ? parseInt(this.initiatedId) : 0
        });
      }
      else if (manualDisconnect) {
          try { 
            if(this.step !== "missed") {
              let initiationId = sessionStorage.getItem('initiationId');
              let data = { ...this.logObject.endCustomer.queuePage.droppedManually };
              data.currentProcess = "Closed manually in Queue"
              data.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,data);
            }
          } catch(err){
            console.log(err);
          }
        this.socket.emit("manualDisconnection", {
          requestId: this.requestId,
          interrupted: currentTime,
          abruptDrop:
            this.waitingStage || this.ringgingState
              ? currentTime + offset * 3600000
              : 0,
          missedCall: this.missedCallUpdateFromBackend,
          initiatedId: this.initiatedId !== null ? parseInt(this.initiatedId) : 0
        });
      } 
      else if(this.noBanker){
        try {
            // Custom message when banker is not available
            let initiationId = sessionStorage.getItem('initiationId');
            let data = { ...this.logObject.endCustomer.queuePage.unavailableAgent };
            data.currentProcess = "Call interrupted due to unavailability of representative.";
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
        } catch(err){
          console.log(err);
        }
      } else {
        if(!sessionStorage.getItem("InitiateQueueRefreshed")){
          try {
            let initiationId = sessionStorage.getItem('initiationId');
            let data = {}
            data.currentProcess = "Call interrupted due to the error, something went wrong";
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          } catch(err){
            console.log(err);
          }
        }
      }
    },
    showRescheduleWarning(){
      this.rescheduleWarning = !this.rescheduleWarning
    },
    reschedule() {
      this.waitingStage = false;
      this.rescheduleClicked = true;
      this.interruptCall({ event: "reschedule" });
      this.$router.replace({
        name: "reschedule",
        params: { incoming: "queue" },
      });
    },
    tryAgain() {
      this.socket.emit("manualDisconnection");
      this.callInterrupt = true;
      if(sessionStorage.getItem("disableInstructionsPage")){
        this.$router.replace({ name: "prerequisite" });
      } else {
        this.$router.replace({ name: "instructions" });
      }
    },
    isEmail(email) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    },
    isMeetingCode(code) {
      var regex = /^\d{6}$/;
      return regex.test(code);
    },
    async updateCallStartTime() {
      if (!this.timeStampsUpdated) {
        this.timeStampsUpdated = true;
        try {
          await axiosInstance.post(
            end_points.update_call_timestamp(this.requestId),
            {
              callStart: Date.now() + offset * 3600000,
            }
          );
        } catch (err) {
          console.log("Something went wrong in updating time stamps", err);
        }
        window.parent.postMessage({ message: "eventOnCallStart" }, "*");
      }
    },
    async validateAndProceed() {
      try {
        this.alreadyProceeding = true
        if (this.isEmail(this.email)) {
          if (this.isMeetingCode(this.meetingCode)) {
            // send notification in case of browser minimization
            if (document.hidden && Notification.permission == "granted" && !this.notficationSent) {
              try {
                this.showLocalNotification(`Ring Ring!`, `Incoming call from ${this.displayName} representative`, this.worker);
                this.notficationSent = true;
              } catch (err) {
                console.log("Error in sending notification", err);
                this.notficationSent = false;
              }
            }
            this.videoStream.getTracks().forEach((track) => {
              //removed track before stoping from media stream object to resolve freezing chrome on android 11
              this.videoStream.removeTrack(track);
              track.stop();
            });
            this.$store.commit("setEndUserData", ["sessionId", this.sessionId]);
            this.$store.commit("setEndUserData", ["email", this.email]);
            this.$store.commit("setEndUserData", [
              "meetingCode",
              this.meetingCode,
            ]);
            this.waitingStage = false;
            await this.updateCallStartTime();
            this.$store.commit("setInitiated", true);
              this.$router.replace({
              name: "startVideo",
            });

          } else {
            eventBus.$emit("vueSnack", "Not a valid Meeting Code");
          }
        } else {
          eventBus.$emit("vueSnack", "Not a valid email id!");
        }
      } catch (e) {
        console.log("error", e);
        eventBus.$emit("vueSnack", "Invalid User");
      }
    },
    /**
     * Error handling. 
     **/
    stopEverythingAndInterrupt() {
      if(this.rescheduleWarning){
        this.rescheduleWarning = false;
      }
      if (this.poller) {
        window.clearInterval(this.poller);
      }
      window.clearTimeout(this.adminCallTimeout);
      window.clearInterval(this.timeInterval);
      this.interruptCall();
    },
    pollForData() {
      let options = {
        url: base_url + end_points.polling_api(this.requestId),
        method: "GET",
      };
      let selfIns = this;
      if (this.pollingRequestCompleted) {
        this.pollingRequestCompleted = false;
        axiosInstance(options)
          .then((data) => {
            try {
              this.pollingRequestCompleted = true;
              if (parseInt(this.pollingFailure) > 0) {
                this.pollingFailure -= 1;
              }
              let availableBackopCount, qPos;

              // Checking if it is a skill tagged call
              if (data && data.data && data.data.waitInfo && data.data.waitInfo.isSkillTaggedCall) {
                availableBackopCount = data.data.waitInfo.skillTagAvailableBackopCount ? data.data.waitInfo.skillTagAvailableBackopCount : 0;
                // setting qPos as -1 in case value is not received,
                // this will throw user an appropriate message
                qPos = data.data.waitInfo.qPosSkill ? data.data.waitInfo.qPosSkill : -1;
              } else {
                availableBackopCount = data.data.waitInfo.availableBackopCount ? data.data.waitInfo.availableBackopCount : 0;
                qPos = data.data.waitInfo.qPos ? data.data.waitInfo.qPos : -1;
              }
              if (availableBackopCount < 1) {
                if (this.errorThreshold > 10) {
                  //This happnes when no banker is available
                  this.noBanker = true;
                  this.stopEverythingAndInterrupt();
                  this.isCiti = this.$store.getters.envValues?.client == 'citi' ? true : false;
                  this.showNoUserAvailable = true;
                }
                this.errorThreshold += 1;
              } else if (qPos < 1) {
                if (this.errorThreshold > 10) {
                  //This is where the call is pushed to enternity wait time,
                  this.stopEverythingAndInterrupt();
                  this.showSomethingWentWrong = true;
                }
                this.errorThreshold += 1;
              } else {
                selfIns.numPeople = qPos;
                selfIns.numPeople = selfIns.numPeople > 1 ? selfIns.numPeople : 1;
                if(selfIns.numPeople > selfIns.qPosHistory){
                  selfIns.priorityUserJoined = true;
                  selfIns.qPosHistory = selfIns.numPeople;
                } else {
                  selfIns.qPosHistory = selfIns.numPeople;
                }
                selfIns.numPeople = Math.ceil(
                  selfIns.numPeople / availableBackopCount
                );
                selfIns.eta =
                  parseInt(selfIns.callData.configuration.averageETA || 1) *
                  selfIns.numPeople;
                if(!this.islogReported && !sessionStorage.getItem("InitiateQueueRefreshed")){
                  try {
                    let initiationId = sessionStorage.getItem('initiationId');
                    let formdata = {};
                    formdata.qpos = data.data.waitInfo.qPos;
                    formdata.waitTime = selfIns.eta;
                    formdata.availableBackopCount = availableBackopCount;
                    formdata.onPage = "Queue";
                    formdata.currentProcess = "Queue position and backops count logged end user initiated call"
                    formdata.callId = this.requestId ? this.requestId : "";
                    logReportData(this.socket,initiationId,formdata);
                  } catch(err){
                    console.log(err);
                  }
                  this.islogReported = true
                }
              }
                
            } catch (error) {
              selfIns.numPeople = 1;
              this.pollingRequestCompleted = true;
            }
  
            
            if (
              data &&
              data.data &&
              data.data.output &&
              !data.data.waitInfo &&
              data.data.output.createSessionResp &&
              data.data.output.createSessionResp.result &&
              data.data.output.createSessionResp.result.userautoLoginConfUrl
            ) {
              try {
                let initiationId = sessionStorage.getItem('initiationId');
                let formdata = {};
                if(this.rescheduleWarning){
                  this.rescheduleWarning = false;
                }
                formdata.actualWaitTime =  (Date.now() - data.data.initiated)/1000;
                formdata.callDuration = {};
                formdata.callDuration.startTime = Date.now();
                formdata.currentProcess = "call dequeued"
                this.islogReported = false;
                formdata.vcipAdminDetails = data.data.output.vcipAdminDetails;
                formdata.callId = this.requestId ? this.requestId : "";
                logReportData(this.socket,initiationId,formdata);
              } catch(err){
                console.log(err);
              }
              this.step = "picked";
              let url =
                data.data.output.createSessionResp.result.userautoLoginConfUrl;
              [this.sessionId, this.email] = url
                .split("/start/")[1]
                .split("?email=");
              [this.email, this.meetingCode] = this.email.split("&meetingCode=");
              let sms = new SignzyMessagingService(this.sessionId);
              this.ringgingState = true;
              sms.on("adminAlive", (data) => {
                console.log("Admin alive received")
                if (this.poller) {
                  window.clearInterval(this.poller);
                }
                window.clearTimeout(this.adminCallTimeout);
                window.clearInterval(this.timeInterval);
                this.ringgingState = false;
                if(!this.alreadyProceeding){
                  console.log("already proceeding")
                    // sms.emit("callDequeued", true);
                    this.validateAndProceed();
                    this.alreadyProceeding = true
                }
                
              });
              //Admin Kickout code starts
              if (this.poller) {
                window.clearInterval(this.poller);
              }
              let adminCallTimeoutDuration = this.callData.configuration
                .adminCallTimeoutDuration
                ? this.callData.configuration.adminCallTimeoutDuration
                : 40;
              //Start SMS configurations here
              this.ringerInterval(adminCallTimeoutDuration);

              this.adminCallTimeout = setTimeout(async () => {
                try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let data = { ...this.logObject.reAgent.timeoutError };
                  data.currentProcess = "Call missed by banker"
                  data.onPage = "Queue";
                  data.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,data);
                } catch(err){
                  console.log(err);
                }
                eventBus.$emit("vueSnack", "Representative didn't respond!");
                this.missedCallUpdateFromBackend = false;
                //mark as missedCall for the RE
                this.step = "missed";
                await axiosInstance.post(
                  end_points.register_missed_call(this.requestId),
                  {
                    vcipId: data.data.backopAdminId,
                  }
                );
                window.parent.postMessage({ message: "eventOnCallMissed" }, "*");
              }, adminCallTimeoutDuration * 1000);
              //
            }
          })
          .catch((err) => {
              this.pollingFailure += 1;
              console.log("Failure in polling request", err);
              if (this.pollingFailure && parseInt(this.pollingFailure) >= 10) {
                console.log("Stopping Polling beacuse of more than 10 consecutive failures");
                // Stopping Polling here
                this.pollingRequestCompleted = false;
                this.stopEverythingAndInterrupt();
                this.showSomethingWentWrong = true;
              } else {
                this.pollingRequestCompleted = true;
              }
          });
      }
    },
    async workOnNewCall() {
      let selfIns = this;
      window.clearTimeout(this.adminCallTimeout);
      this.step = "initiate";
      this.missedCallUpdateFromBackend = true;
      //Setting values in session storage so that can be used at drophandler
      if(typeof selfIns.callData.configuration.showScheduling != 'undefined') {
        this.showReschedule = selfIns.callData.configuration.showScheduling;
        sessionStorage.showScheduling = selfIns.callData.configuration.showScheduling;
      }
      sessionStorage.displayName = selfIns.callData.configuration.displayName;
      if (this.callData.directAssignment) {
        //THis is for direct assignment as is supposed to be commented !!
        /*
        let directData = (
          await axiosInstance.get(
            end_points.initiate_direct_call(this.requestId)
          )
        ).data;

        let url = directData.autoConfUrl;
        [this.sessionId, this.email] = url.split("/start/")[1].split("?email=");
        [this.email, this.meetingCode] = this.email.split("&meetingCode=");
        let sms = new SignzyMessagingService(this.sessionId);
        sms.on("adminAlive", data => {
          window.clearInterval(this.poller);
          window.clearTimeout(this.adminCallTimeout);
          this.validateAndProceed();
        });

        //Admin Kickout code starts
        window.clearInterval(this.poller);
        let adminCallTimeoutDuration = this.callData.configuration
          .adminCallTimeoutDuration
          ? this.callData.configuration.adminCallTimeoutDuration
          : 20;
        //Start sms configurations here

        this.adminCallTimeout = setTimeout(async () => {
          eventBus.$emit("vueSnack", "Representative didn't respond!");
          //Letting the call status get deffered, such that when the representative get's online he doesnt get this call.
          //Registering the cass as missed call
          this.missedCallUpdateFromBackend = false;
          await axiosInstance.post(
            end_points.update_direct_call_status(selfIns.requestId),
            {
              status: "deferred",
              missedCallFor: this.callData.directAssignment.backopsId
            }
          );
        }, adminCallTimeoutDuration * 1000);
        */
      } else {
        if(this.isMultipleSession) //if multiple session dont create call
          return;
        await axiosInstance.post(end_points.start_call, {
          requestId: this.requestId,
        }).then((resp) => {
          if (resp.data && resp.data.message && resp.data.message.status === "Success") {
            this.initiatedId = resp.data && resp.data.message && resp.data.message.initiatedId && resp.data.message.initiatedId
            this.socket.emit("saveInitiatedId", {
              initiatedId: this.initiatedId
            })
            this.errorThreshold = 0;
    
            this.poller = setInterval(() => {
              if (this.requestId) {
                this.pollForData();
                this.showTryAgain = false;
              } else {
                eventBus.$emit("vueSnack", "RequestId Missing, Please try again!!!");
                console.log("RequestId Missing, Stopping Polling");
                try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let data = {}
                  data.currentProcess = "RequestId Missing"
                  logReportData(this.socket,initiationId,data);
                } catch(err){
                  console.log("Missing RequestId block::",err);
                }
                this.stopEverythingAndInterrupt();
                this.showSomethingWentWrong = true;
                this.showTryAgain = true;
              }
            }, this.pollInterval * 1000);
    
            setTimeout(function () {
              selfIns.disableReschedule = false;
            }, selfIns.showRescheduleButtonInterval * 1000);
          } else if (resp.data && resp.data.message && resp.data.message.status === "failed") {
            console.log("Failure in initate Call ::::::", resp.data.message.reason && resp.data.message.reason);
            eventBus.$emit("vueSnack", "Could not initiate your call, please try again");
            this.stopEverythingAndInterrupt();
            this.showSomethingWentWrong = true;
            this.showTryAgain = true;
          } else {
            console.log("Something went wrong");
            eventBus.$emit("vueSnack", "Failed to initaite your call, Please retry!");
          }
        }).catch((err) => {
          console.log("Something failed during initiate Call", err);
          eventBus.$emit("vueSnack", "Could not initiate your call, please try again");
          this.stopEverythingAndInterrupt();
          this.showSomethingWentWrong = true;
          this.showTryAgain = true;
        })
      }
      // this.youtubeEmbed = this.callData.configuration.advideo;
      // this.eta = this.callData.configuration.averageETA;
      // if (!this.youtubeEmbed.includes("&output=embed")) {
      //   this.youtubeEmbed += "&output=embed";
      // }
    },
  },
  props: ["requestId", "callData", "socket", "worker" ,"isMultipleSession"],
};
</script>

<style scoped>
.timewrapper {
  border: 1px white solid;
  border-radius: 5px;
  padding: 10px;
  color: #46b6e5;
  font-weight: 900;
  
}
.salutation {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 3vh;
  line-height: 1em;
  color: black !important;
}
.instructionText {
  font-size: 16px;
}
.feedback {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.permissionProgress {
  position: absolute;
  color: white;
  padding-bottom: 20px;
  padding-left: 4%;
  padding-right: 4%;
}
.videoGrad {
  background: transparent
    linear-gradient(180deg, #00000000 0%, #00000004 26%, #000000 100%) 0% 0%
    no-repeat padding-box;
  border-bottom: 7px solid #056dad;
}
#videoGradient {
  position: fixed;
}
#reflection {
  width: 100%;
  background: black;
}
.customBtn {
  color: white;
  width: 200px;
}
.subtitle-2 {
  font-size: 0.775rem !important
}
</style>